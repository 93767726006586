/** @format */

.your-goals-container {
  * {
    box-sizing: border-box;
  }

  display: flex;
  // flex-direction: column;
  // align-items: center;
  overflow: hidden;
  height: 100%;

  .your-goals-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    // padding-bottom: 30px;
    overflow-y: auto;

    h2:not(.dds-goals-carousel-goals-slide__title) {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: #231052;
    }

    .your-goals-wrapper {
      display: flex;
      flex-direction: column;
      width: 81%;
      margin-top: 51px;
      max-width: 1080px;

      .your-goals-header {
        display: flex;
        margin-bottom: 19px;
        margin-left: 15px;
        align-items: center;
        h2{
          font-weight: 700;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          /* identical to box height, or 17px */

          display: flex;
          align-items: center;

          /* Light Surface/Text_Strong */

          color: #231052;
          margin-left: 10px;

          margin-top: 0;
          position: relative;
          top: 6px;
        }
      }

      .your-goals-body {
        width: 100%;
        max-height: 302px;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 64px;

        .your-goals-list {
          list-style: none;
          margin-block-start: 0;
          margin-block-end: 0;
          padding-inline-start: 0;
          min-width: 390px;
          width: 50%;
          display: flex;
          flex-direction: column;
          padding-right: 10px;
          border-radius: 15px;
          overflow-y: auto;

          &-item {
            .dds-your-goals-card {
              border-radius: inherit;
              height: 135px !important;
            }

            &:first-of-type {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
            }

            &:last-of-type {
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
            }
          }
        }

        .your-goal-card {
          width: 50%;

          &.no-goals {
            width: 100%;
          }

          .delete-check {
            display: flex;
            flex-direction: column;
            height: 100%;
            background-color: #231052;
            border-radius: 15px;
            min-height: 302px;
            justify-content: space-between;

            .delete-text {
              display: flex;
              padding-top: 60px;
              padding-left: 50px;
              flex-direction: column;

              h3 {
                font-family: "Lato";
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                color: #fff;
              }

              p {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #aeabb8;
              }
            }

            .delete-actions {
              display: flex;
              align-items: flex-end;
              height: 100%;
              justify-content: flex-end;
              padding-bottom: 30px;
              padding-right: 30px;

              button {
                margin-right: 10px;
              }
            }
          }
        }
      }
      .your-programs {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        width: 100%;
        margin-bottom: 64px;

        .your-saved-programs {
          width: 50%;

          .your-saved-header {
            display: flex;
            align-items: center;
            margin-left: 15px;
            margin-bottom: 29px;

            h2 {
              margin-right: 18px;
              font-weight: 700;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 140%;
              /* identical to box height, or 17px */

              display: flex;
              align-items: center;

              /* Light Surface/Text_Strong */

              color: #231052;
              margin-left: 8px;

              margin-top: 0;
              position: relative;
              top: 6px;
            }
          }

          .saved-programs {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border-radius: 15px;
            height: 600px;
            overflow-y: auto;
            overflow-x: hidden;

            .saved-filter-header {
              padding-left: 20px;
              .saved-filter-header-dropdowns {
                display: flex;
              }
            }
          }
        }

        .your-active-programs {
          width: 50%;

          .your-active-header {
            display: flex;
            align-items: center;
            margin-left: 15px;
            margin-bottom: 29px;
            h2 {
              margin-right: 18px;
              font-weight: 700;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 140%;
              /* identical to box height, or 17px */

              display: flex;
              align-items: center;

              /* Light Surface/Text_Strong */

              color: #231052;
              margin-left: 8px;

              margin-top: 0;
              position: relative;
              top: 6px;
            }
          }

          .active-programs-wrapper {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border-radius: 15px;
            height: 600px;
            overflow-y: auto;
            overflow-x: hidden;

            .active-programs-list {
              // background-color: rgb(250, 0, 167);
              max-height: 850px;
            }
          }
        }
      }

      .your-archived-wrapper {
        padding-right: 8px;

        h2 {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 32px;
          display: flex;
          align-items: center;

          /* Light Surface/Text_Medium */

          color: #6b6185;
        }

        h3 {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 32px;
          display: flex;
          align-items: center;

          /* Light Surface/Text_Medium */

          color: #8361ff;
        }

        p {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #6b6185;
        }

        .archived-add-solution {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 300px;
          width: 39.87%;
          background-color: green;
          border-radius: 10px;

          .archived-add-solution-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            h3 {
              margin-bottom: 20px;
            }
          }
        }

        .archived-total {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #fff;
          height: 300px;
          width: 50%;
          border-radius: 15px;
          margin-bottom: 30px;
          .archived-card-text {
            width: 100%;
            padding-left: 45px;
            margin-bottom: 20px;
          }

          .archived-card-action {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            p {
              margin: 0px;
            }

            .archived-card-action-text {
              width: 40%;
              padding-left: 45px;
            }

            .archived-card-action-btn {
              width: 30%;
            }
          }
        }
      }
    }
  }
}

.my-solutions-modal-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  padding: 2px;
  z-index: 1;
  border: none;
  width: 60%;
  max-width: 1080px;
}
