/** @format */

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,500,700");
@import "../styles/variables";
@import "../styles/typography";

/* total width */
::-webkit-scrollbar,
::-webkit-scrollbar-button,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  background-color: transparent;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid transparent;
  background-clip: content-box;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

html {
  overflow: hidden;
}

html,
body {
  background-color: #f4f5f8;
  font-family: "Lato", sans-serif;
  min-width: 700px;
  position: relative;
  height: 100%;
  margin: 0;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  html,
  body {
    min-width: 100%;
    height: auto;
  }
}

.App {
  text-align: center;
  max-width: 1080px;
  min-width: 1000px;
  margin: 0 auto;
  padding-bottom: 78px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.content-container {
  grid-area: content;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  overflow: auto;
  margin-left: 70px;
}

.new-content-container {
  position: fixed;
  left: 255px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  margin-top: 78px;
}

.Header {
  margin-left: 40px;
  margin-top: 20px;
  height: 20px;
  color: #525a64;
  font-family: Lato;
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
}

.app-container {
  display: grid; 
  grid-template-columns: 0 1fr; 
  grid-template-rows: auto 1fr; 
  gap: 0 0; 
  grid-template-areas: 
    "navbar topbar"
    "navbar content"; 
  width: 100%;
  height: 100%;
  overflow: hidden;

  .anonymity-warning {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    padding: 18px;
    padding-left: 36px;

    button[aria-label='Close'] {
      display: none;
    }
  }

  .dds-navbar {
    grid-area: navbar;
    position: static;
    overflow-y: auto;
  }
}

.transparent-header {
  font-weight: 500;
  color: #525a64;
  text-align: left;
  padding: 0 10px;
  margin-bottom: 15px;
}

.hidden {
  display: none !important;
}

.error-message > div > div {
  background-color: red;
}

.general-message > div > div {
  background-color: gray;
}

.success-message > div > div {
  background-color: green;
}

.warning-message > div > div {
  background-color: yellow;
}

#all-post-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 40% 40% 20%;
  grid-template-rows: auto 229px 400px;
  grid-template-areas:
    "s s s"
    "f f f"
    "p p a"
    "p p t";
  margin-top: 62px;
}

#all-post-container .profile-container {
  grid-area: s;
}

#all-post-container .filter-container {
  grid-area: f;
  margin: 25px;
}

#all-post-container .create-post-container {
  display: none;
}

#all-post-container .post-container {
  grid-area: p;
  margin: 25px;
}

#all-post-container .personal-container {
  display: none;
}

#all-post-container .activity-container {
  grid-area: a;
  margin: 25px;
}

#all-post-container .trending-container {
  grid-area: t;
  margin: 25px;
}

#personal-post-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 40% 40% 20%;
  grid-template-rows: auto 229px 400px;
  grid-template-areas:
    "s s s"
    "p p c"
    "p p a"
    "p p t";
  margin-top: 62px;
}

#personal-post-container .profile-container {
  grid-area: s;
}

#personal-post-container .filter-container {
  display: none;
}

#personal-post-container .create-post-container {
  grid-area: c;
  margin: 25px;
}

#personal-post-container .personal-container {
  grid-area: p;
  margin: 25px;
}

#personal-post-container .hidden-container {
  display: none;
}

#personal-post-container .activity-container {
  grid-area: a;
  margin: 25px;
}

#personal-post-container .trending-container {
  grid-area: t;
  margin: 25px;
}

.load-more-container {
  width: 100%;
}

.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 25px;
  box-shadow: 6px 12px 30px rgba(0, 0, 0, 0.06);
}

.ds-toast-component {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  animation: 0.4s ease-out 0s 1 slideInFromTop;

  &__goals_page {
    width: 100%;
    position: absolute;
    top: 79px;
    left: 0;
    z-index: 1;
    animation: 0.4s ease-out 0s 1 slideInFromTop;
  }
}

.chip-container-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  .chip-wrapper {
    margin-right: 10px;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  25% {
    transform: translateY(-75%);
    opacity: 0;
  }

  50% {
    transform: translateY(-50%);
    opacity: 0;
  }

  85% {
    transform: translateY(-5%);
    opacity: 0.25;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.dds-navbar:not(.dds-navbar--collapsed) {
  li:has(.lumi-navbar){
      background: linear-gradient(180deg, $primary80 0%, $primary100 100%);
      border-radius: 10px;
       div{
          color: $white;
      }
  }
}
